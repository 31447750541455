exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-curriculum-js": () => import("./../../../src/pages/curriculum.js" /* webpackChunkName: "component---src-pages-curriculum-js" */),
  "component---src-pages-ideas-js": () => import("./../../../src/pages/ideas.js" /* webpackChunkName: "component---src-pages-ideas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-novel-js": () => import("./../../../src/pages/novel.js" /* webpackChunkName: "component---src-pages-novel-js" */),
  "component---src-pages-podcast-js": () => import("./../../../src/pages/podcast.js" /* webpackChunkName: "component---src-pages-podcast-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sports-backup-js": () => import("./../../../src/pages/sports-backup.js" /* webpackChunkName: "component---src-pages-sports-backup-js" */),
  "component---src-pages-sports-js": () => import("./../../../src/pages/sports.js" /* webpackChunkName: "component---src-pages-sports-js" */)
}

